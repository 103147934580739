@include font-face('SF Pro Display', '../../fonts/SF-Pro-Display-Regular', 400, normal, otf);
@include font-face('SF Pro Display', '../../fonts/SF-Pro-Display-RegularItalic', 400, italic, otf);

@include font-face('SF Pro Display', '../../fonts/SF-Pro-Display-Medium', 600, normal, otf);
@include font-face('SF Pro Display', '../../fonts/SF-Pro-Display-MediumItalic', 600, italic, otf);

@include font-face('SF Pro Display', '../../fonts/SF-Pro-Display-Semibold', 700, normal, otf);
@include font-face('SF Pro Display', '../../fonts/SF-Pro-Display-SemiboldItalic', 700, italic, otf);

@include font-face('SF Pro Display', '../../fonts/SF-Pro-Display-Bold', 800, normal, otf);
@include font-face('SF Pro Display', '../../fonts/SF-Pro-Display-BoldItalic', 800, italic, otf);

@include font-face('SF Pro Display', '../../fonts/SF-Pro-Display-Black', 900, normal, otf);
@include font-face('SF Pro Display', '../../fonts/SF-Pro-Display-BlackItalic', 900, italic, otf);

html {
  font-size: 62.5%;
}

body {
  font-family: SF Pro Display, sans-serif !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  font-size: 16px;
}

.underline {
  text-decoration: underline;
}
.white {
  color: white;
}

.semimedium {
  font-weight: 500;
}

.medium {
  font-weight: 600;
}

.semibold {
  font-weight: 700;
}

.bold {
  font-weight: 800;
}

.black {
  font-weight: 900;
}

.l1 {
  font-size: 40px;
}

.m1 {
  font-size: 28px;
}

.m2 {
  font-size: 26px;
}

.m3 {
  font-size: 24px;
}

.m4 {
  font-size: 22px;
}

.m5 {
  font-size: 20px;
}


.s1 {
  font-size: 18px;
}

.s2 {
  font-size: 16px;
}

.s3 {
  font-size: 14px;
}

.s4 {
  font-size: 12px;
}

.s5 {
  font-size: 10px;
}