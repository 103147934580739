.user-vibe {
  margin: 24px 16px;

  &--text {
    text-align: center;
  }

  &--list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 18px;

    &--item {
      padding: 8px 8px 8px 12px;
      background-color: white;
      border-radius: 100px;
      margin: 5px 3px;

      display: flex;
      align-items: center;

      cursor: crosshair;
      user-select: none;

      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.13);
      &.selected {
        border: 1px solid #EDEDED;
        box-shadow: none;
        box-sizing: border-box;
        cursor: default;
      }

      &--click {
        height: 24px;
        width: 24px;
        margin-left: 4px;

        display: flex;
        justify-content: center;
        align-items: center;

        &-count {
          background-color: $grey;
          border-radius: 100%;

          &.selected {
            background-color: $green;
            color: white;
          }
        }
      }
    }
  }
}