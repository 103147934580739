.collect-vibe {
  height: 100%;
  width: 100%;
  position: relative;
  max-width: 414px;
  background-color: $sky-blue;

  overflow: scroll;

  &__sticky-redirect {
    position: sticky;
    bottom: 0;
    padding: 20px 32px;
    padding-top: 0;
  }
}