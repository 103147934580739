/*
   Mq mixins
*/
$mq-responsive: true;
$mq-breakpoints: (
  mobile:   320px,
  mobile-max: 450px,
  tablet:   768px,
  laptop:   1024px,
  desktop:  1280px,
  wide:     1440px,
);
$mq-static-breakpoint: desktop;
$mq-show-breakpoints: ();

$opacity-hover: 0.6;

$sky-blue: #F5F8FD;
$snapchat: #fffc00;
$grey: #F4F4F4;
$green: #64DF80;

