.user-profile {
  border-radius: 15px;
  overflow: hidden;

  margin: 15px 25px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.13);

  &--gallery {
    position: relative;
    background-size: cover;
    background-position: center;
    height: 480px;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &--progress {
      height: 2.5px;
      margin: 12px 9px;
      display: grid;
      grid-gap: 7px;

      &--item {
        height: 100%;
        flex: 1;
        border-radius: 1px;

        background-color: white;
        &:not(.selected) {
          opacity: 0.1;
        }
      }
    }

    &--info {
      color: white;

      display: flex;
      align-items: center;
      margin: 16px 21px;
      p:nth-child(2) {
        margin-left: 8px;
      }
    }

    &--logo {
      position: absolute;
      height: 40px;
      width: 40px;
      top: 22px;
      right: 16px;
    }

    &--go {
      &-right, &-left {
        position: absolute;
        z-index: 10;
        height: 100%;
        width: 50%;
      }
      &-right {
        right: 0;
      }
      &-left {
        left: 0;
      }
    }
  }
}