.mq-nm {
  @include mq($until: tablet) {
    display: none !important;
  }
}

.mq-nt {
  @include mq($from: tablet, $until: laptop) {
    display: none !important;
  }
}

.mq-nl {
  @include mq($from: laptop) {
    display: none !important;
  }
}
