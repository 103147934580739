.home {
  height: 100%;
  width: 100%;
  &__header {
    display: flex;
    justify-content: center;
    margin-top: 112px;
    @include mq($from: laptop) {
      margin-top: 255px;
      svg {
        width: 250px;
      }
    }
  }

  &__footer {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;

    &__store {
      display: flex;
      justify-content: center;
      margin-bottom: 5px;
    }

    &__nav {
      @include mq($from: laptop) {
        display: flex;
        background-color: white;
        align-items: center;
        justify-content: space-between;

        span {
          color: #7C7C7C;
        }
        padding: 20px 72px;
      }

      &__links {
        margin: 25px;
        @include mq($from: laptop) {
          margin: 0;
        }
        display: flex;
        justify-content: center;

        a, .a {
          margin-left: 60px;
          &:first-child {
            margin-left: 0;
          }
        }
      }

      &__store {
        display: flex;

        a, .a {
          margin-left: 34px;
        }
      }
    }
  }
}

.appstore {
  width: 228px;
  @include mq($from: laptop) {
    width: auto;
    height: 54px;
    svg {
      height: 54px;
    }
  }
}

.googleplay {
  width: 264px;
  @include mq($from: laptop) {
    width: auto;
    height: 54px;
    img {
      height: 54px;
    }
  }
}