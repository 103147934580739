.redirect-button {
  background: $snapchat;
  border-radius: 30px;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.1));
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;

  svg {
    margin-left: 8px;
    height: 20px;
    width: 20px;
  }
}