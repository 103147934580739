html {
  // overflow: hidden;

  &.js {
    opacity: 0;
    transition: opacity .2s ease-in-out;

    // Here is DOMContentLoaded state
    &.ready {
      opacity: 1;
    }
  }
  -webkit-text-size-adjust: none;
}

body {
  background: white;
  overflow: hidden;
}

html, body, #root {
  height: 100%;
  width: 100%;
}

body, #root {
  display: flex;
  justify-content: center;
  align-items: center;
}

* {
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
    width: 0 !important;
    height: 0 !important;
  }
  -webkit-tap-highlight-color: transparent;
}

a, .link {
  color: inherit;
  text-decoration: none;
  @include mq($and: '(hover: hover)') {
    &:hover {
      cursor: crosshair;
      opacity: $opacity-hover;
    }
  }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  appearance: none;
  margin: 0; 
}

input, select, button {
  outline: 0;
  border-radius: 0;
  padding: 0;
  margin: 0;
}

input:-internal-autofill-selected,
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
  -webkit-box-shadow: inset 0 0 0px 9999px white;
}
select:-internal-autofill-selected,
select:-webkit-autofill,
select:-webkit-autofill:hover, 
select:-webkit-autofill:focus, 
select:-webkit-autofill:active  {
  -webkit-box-shadow: inset 0 0 0px 9999px white;
}

h1, h2, h3, h4, h5, h6, p {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

img {
  max-width: 100%;
  display: block;
  width: auto;
  height: auto;
}

svg {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.font-preload {
  position: fixed;
  opacity: 0;
}

.background-image {
  height: 100%;
  width: 100%;

  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.background-image-cover {
  background-size: cover;
}

.uppercase {
  text-transform: uppercase;
}