.bg-video {
  position: fixed;
  top: 0; bottom: 0;
  left: 0; right: 0;
  z-index: -1;

  video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }
}